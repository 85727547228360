<template>
  <CustomForm
      v-slot="{ errors }"
      @submit="onSubmit"
      class="w-full max-w-[524px] m-auto px-5 lg:px-0"
  >
    <div class="w-full pt-10 pb-20 sm:py-[81px]">
      <div class="transition-all duration-300 ease-linear">
        <YourInformation :yourInfo="yourInfo" :errors="errors"/>
        <div class="pt-6 pb-[31px] border-b border-b-light-gray">
          <SharedCheckBox label="Sign up for Vinderkind emails" v-model="renewYear"/>
        </div>
        <DeliverInformation :deliveryInfo="deliveryInfo" :errors="errors"/>
        <GiftInformation :errors="errors" :index="0" v-if="false"/>
        <PaymentInformation
            :errors="errors"
            page="checkout"
        />
        <button
            :disabled="spinning"
            class="mt-[15px] mb-[18px] bg-vivid-purple w-full py-4 text-[21px]/[25px] text-white rounded-small font-bold hover:opacity-90 transition-all ease-in duration-150"
        >
          <span
              v-if="spinning"
              class="px-[15px] cursor-pointer flex items-center bg-vivid-purple rounded-small justify-center w-full text-white gap-[13px] sm:gap-5 hover:opacity-85 transition-all duration-200 ease-linear [&_.loader]:!min-h-5 [&_.loader]:!h-5 [&_.item]:!w-6 [&_.item]:!h-6 [&_.item]:!border-2"
          >
            <CustomLoader/>
          </span>
          <span v-else>
            Pay ${{ Number(calculateTotal()).toFixed(2) }}
          </span>
        </button>
        <div class="text-[13px]/[17px] flex justify-center gap-1">
          <span class="text-center">By continuing you agree with our</span>
          <button @click.prevent="openModal" class="text-center text-vivid-purple">
            terms and conditions.
          </button>
        </div>
      </div>
    </div>
  </CustomForm>

  <SharedModal v-model="open">
    <TermsConditions @close="closeModal"/>
  </SharedModal>
</template>

<script lang="ts">
import SharedCheckBox from '@components/reusable/SharedCheckBox.vue'
import YourInformation from '@components/sign-up/YourInformation.vue'
import DeliverInformation from '@components/sign-up/DeliverInformation.vue'
import GiftInformation from '@components/sign-up/GiftInformation.vue'
import PaymentInformation from '@components/payments/PaymentInformation.vue'
import {defineComponent, onMounted, ref, watch} from 'vue'
import {usePlansStore} from '@stores/plans'
import {Form as CustomForm} from 'vee-validate'
import SharedModal from '@components/reusable/SharedModal.vue'
import TermsConditions from '@components/sign-up/TermsConditions.vue'
import {emailValidation} from '@utills/helpers/validation'
import {useCartsStore} from '@stores/carts'
import CustomLoader from '@components/reusable/CustomLoader.vue'
import {useOrderStore} from '@stores/sign-up'
import {useReCaptcha} from "vue-recaptcha-v3";
import {usePayment} from "@stores/payment";

export default defineComponent({
  components: {
    SharedCheckBox,
    YourInformation,
    DeliverInformation,
    CustomForm,
    GiftInformation,
    PaymentInformation,
    SharedModal,
    TermsConditions,
    CustomLoader
  },
  data() {
    return {
      yourInfo: [
        {
          placeholder: 'Full name',
          field: 'name',
          valid: this.required
        },
        {
          placeholder: 'Email',
          field: 'email',
          valid: this.emailValidation
        }
      ],
      deliveryInfo: [
        {
          placeholder: 'Name',
          field: 'delivery_name',
          valid: this.required,
        },
        {
          placeholder: 'Address',
          field: 'delivery_line_one',
          valid: this.required,
        },
        {
          placeholder: 'Address Line 2',
          field: 'delivery_line_two',
        },
        {
          placeholder: 'City',
          field: 'delivery_city',
          valid: this.required,
        },
        {
          placeholder: 'State',
          field: 'delivery_state',
          valid: this.required,
        },
        {
          placeholder: 'Zip Code',
          field: 'delivery_zip',
          valid: this.required,
          handler: true
        }
      ]
    }
  },

  setup() {
    const {getPaymentData} = usePayment()
    const signUp = useOrderStore()
    const reCaptcha = useReCaptcha();
    const {carts, calculateTotal, cartResponse} = useCartsStore()
    let zipCode = ref('')
    let spinning = ref(false)
    const {publication} = usePlansStore()
    let choosePlan = ref(1)
    let validZips = ['00000']
    let renewYear = ref(false)
    const couponCode = ref('')

    function required(value: string) {
      return value ? true : 'This field is required'
    }

    watch(cartResponse, () => {
      couponCode.value = cartResponse.couponCode;
    })

    async function onSubmit(values: any) {
      spinning.value = true

      const tokenRecaptcha = async () => {
        await reCaptcha?.recaptchaLoaded();
        return reCaptcha?.executeRecaptcha('login');
      }

      const paymentInfo = await getPaymentData()

      if(paymentInfo === 'error'){
        spinning.value = false
        return;
      }

      const customer = {
        name: values.name,
        email: values.email,
        phone: values.phone,
        // signUp: signup
      }

      const address = {
        city: values.delivery_city,
        name: values.delivery_name,
        state: values.delivery_state,
        zip: values.delivery_zip,
        address_line_1: values.delivery_line_one,
        address_line_2: values.delivery_line_two,
      }

      const data = {
        paymentInfo,
        customer,
        products: carts,
        address,
      };

      await signUp.submitOrder(data, '/order-confirmation', await tokenRecaptcha())
    }

    const updateChoosePlan = (planId: number) => {
      choosePlan.value = planId
    }
    const open = ref(false)
    const openModal = () => {
      open.value = true
    }
    const closeModal = () => {
      open.value = false
    }
    return {
      validZips,
      publication,
      choosePlan,
      updateChoosePlan,
      renewYear,
      calculateTotal,
      required,
      onSubmit,
      emailValidation,
      zipCode,
      open,
      openModal,
      closeModal,
      spinning,
      couponCode
    }
  }
})
</script>
