<template>
  <div
    class="w-full border rounded-small shadow-gray pt-4 pb-[13px] px-[18px] text-base sm:text-xl border-charcoal placeholder:text-silver h-[49px] sm:h-[53px]"
  >
    <div class="" id="stripe-card-number"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { useStripe } from "@stores/stripe";

export default defineComponent({
  setup() {
    const storeStripe = useStripe();
    const stripeLoading = ref<boolean>(false);

    onMounted(async () => {
      await storeStripe.initialStripe();
      storeStripe.initialElement("#stripe-card-number");
      stripeLoading.value = true;
    });

    return {
      stripeLoading,
    };
  },
});
</script>
