import { defineStore, type StoreDefinition } from 'pinia'
import {reactive} from "vue";
import {useStripe} from "@stores/stripe";


export const usePayment: StoreDefinition<string> = defineStore('payment', () => {
    const storeStripe = useStripe();
    const paymentMethod = import.meta.env.VITE_PAYMENTS_TYPE
    const paymentData = reactive({
        token: null,
        name: '',
        cardNumber: '',
        exp: '',
        cvv: '',
        zip: ''
    })

    const getPaymentData = async () => {
        switch (paymentMethod){
            case 'stripe':
                return await storeStripe.createToken({
                    name: paymentData.name
                })
            case 'usaepay':
                return {
                    name: paymentData.name,
                    cardNumber: paymentData.cardNumber,
                    exp: paymentData.exp,
                    cvv: paymentData.cvv,
                    zip: paymentData.zip
                }
        }
    }

    const clearPaymentData = () => {
        paymentData.token = null;
        paymentData.name = '';
        paymentData.cardNumber = '';
        paymentData.exp = '';
        paymentData.cvv = '';
        paymentData.zip = '';
    }

    clearPaymentData();

    return {
        getPaymentData,
        clearPaymentData,
        paymentMethod,
        paymentData
    }
})
