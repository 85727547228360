<template>
  <div class="flex flex-col relative">
    <div class="relative">
      <CustomField
        v-model="paymentData.cardNumber"
        name="cardNumber"
        :value="paymentData.cardNumber"
        :rules="[required, cardValidation]"
        placeholder="Card number"
        v-mask="cardMask"
      />
      <img
        v-if="cardLogoUrl"
        :src="cardLogoUrl"
        alt="Card Logo"
        class="absolute top-1/2 right-5 transform -translate-y-1/2 w-12"
      />
    </div>
    <span class="text-red-500 text-xs pt-1">{{ errors.cardNumber }}</span>
  </div>
  <div
    class="mt-[17px] h-[49px] sm:h-[53px] flex gap-1 sm:grid grid-cols-3 border border-charcoal justify-between rounded-small px-5 pt-4 pb-[13px] w-full text-xl/6 placeholder:text-silver focus:outline-vivid-purple"
  >
    <div class="w-max sm:w-full flex flex-col justify-center relative">
      <div>
        <Field
            :name="'expDate'"
            :rules="[required]"
            :placeholder="'Expiration Date'"
            class="text-base sm:text-xl placeholder:text-silver focus:outline-none h-full w-full"
            @blur="focusField"
            @focus="focusField"
            v-mask="'##/##'"
            v-model="paymentData.exp"
        />
        <span
            :class="isFocused ? 'opacity-100 z-[1]' : ' opacity-0 z-0'"
            class="absolute bg-white -top-[23px] left-0 px-1 transition-all duration-75 ease-linear text-sm whitespace-nowrap"
        >Expiration Date</span
        >
      </div>
      <span class="text-red-500 text-xs pt-1 absolute -bottom-8 whitespace-nowrap">
        {{ errors.expDate ? "Field is required" : "" }}
      </span>
    </div><div class="w-max sm:w-full flex flex-col justify-center relative">
    <div>
      <Field
          :name="'cvv'"
          :rules="[required]"
          :placeholder="'CVV'"
          class="text-base sm:text-xl placeholder:text-silver focus:outline-none h-full w-full"
          @blur="focusField"
          @focus="focusField"
          v-mask="'####'"
          v-model="paymentData.cvv"
      />
      <span
          :class="isFocused ? 'opacity-100 z-[1]' : ' opacity-0 z-0'"
          class="absolute bg-white -top-[23px] left-0 px-1 transition-all duration-75 ease-linear text-sm whitespace-nowrap"
      >CVV</span
      >
    </div>
    <span class="text-red-500 text-xs pt-1 absolute -bottom-8 whitespace-nowrap">
        {{ errors.cvv ? "Field is required" : "" }}
      </span>
  </div><div class="w-max sm:w-full flex flex-col justify-center relative">
    <div>
      <Field
          :name="'billing'"
          :rules="[required]"
          :placeholder="'Billing ZIP Code'"
          class="text-base sm:text-xl placeholder:text-silver focus:outline-none h-full w-full"
          @blur="focusField"
          @focus="focusField"
          v-mask="'#####'"
          v-model="paymentData.zip"
      />
      <span
          :class="isFocused ? 'opacity-100 z-[1]' : ' opacity-0 z-0'"
          class="absolute bg-white -top-[23px] left-0 px-1 transition-all duration-75 ease-linear text-sm whitespace-nowrap"
      >Billing ZIP Code</span
      >
    </div>
    <span class="text-red-500 text-xs pt-1 absolute -bottom-8 whitespace-nowrap">
        {{ errors.billing ? "Field is required" : "" }}
      </span>
  </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, type PropType} from "vue";
import { usePayment } from "@stores/payment";
import CustomField from "@components/reusable/CustomField.vue";
import { required } from "@utills/helpers/validation";
import { Field } from "vee-validate";
import cardLogo from "@utills/helpers/constant";

export default defineComponent({
  components: { Field, CustomField },
  props: {
    errors: {
      type: Object,
      required: true
    },
    isFocused: {
      type: Boolean,
      default: false,
    },
    focusField: {
      type: Function as PropType<(...args: any[]) => void>,
      default: () => {},
    },
  },
  setup() {

    const { paymentData } = usePayment();

    const cardLogoUrl = computed(() => {
      const number = paymentData.cardNumber.replace(/\D/g, '')
      for (const card of cardLogo) {
        if (number.match(card.re)) {
          return card.logo
        }
      }
      return ''
    })

    const cardMask = computed(() => {
      const number = paymentData.cardNumber.replace(/\D/g, '')
      for (const card of cardLogo) {
        if (number.match(card.re)) {
          return card?.mask ? card.mask : '#### #### #### ####'
        }
      }
      return '#### #### #### ####'
    })
    const cardValidation = (value: string): boolean | string => {
      const cleanedValue = value.replace(/\D/g, '')
      const regex = /^\d{15,16}$/
      return regex.test(cleanedValue) ? true : 'Invalid card'
    }

    return {
      paymentData,
      required,
      cardValidation,
      cardMask,
      cardLogoUrl,
    };
  },
});
</script>