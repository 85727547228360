import ToastPlugin from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-bootstrap.css'
import "vue-select/dist/vue-select.css";
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import VueTheMask from 'vue-the-mask'
import { VueReCaptcha } from 'vue-recaptcha-v3'

import App from '@/App.vue'
import router from '@router/index'
import { authPlugin } from 'vue3-auth'
import auth from '@api/auth'

// Create the Vue application
const app = createApp(App);

// Register plugins and components
app.use(createPinia());
app.use(router);
app.use(authPlugin, auth)
app.use(VueReCaptcha, {
    siteKey: import.meta.env.VITE_RECAPTCHA_KEY,
    loaderOptions: {
        useRecaptchaNet: true,
        autoHideBadge: false,
    },
})
app.use(ToastPlugin);
app.use(VueTheMask);

// Mount the Vue application
app.mount('#app');
