import type {Stripe, StripeCardElement} from '@stripe/stripe-js';
import type {StoreDefinition} from "pinia";
import {loadStripe} from '@stripe/stripe-js';
import {defineStore} from "pinia";
import {ref} from "vue";
import {useToast} from "vue-toast-notification";

const STRIPE_KEY = import.meta.env.VITE_STRIPE_KEY;

export const useStripe: StoreDefinition<string> = defineStore('stripe', () => {
    const toast = useToast();
    const stripe = ref<Stripe | null>(null);
    const cardElement = ref<StripeCardElement | null>(null);

    const initialStripe = async () => {
        stripe.value = await loadStripe(STRIPE_KEY, {
            locale: 'en'
        });
    };

    const initialElement = (container: string) => {
        if (stripe.value) {
            const elements = stripe.value.elements();
            cardElement.value = elements.create('card', {
                style: {
                    base: {
                        color: '#000000',
                        fontWeight: '500',
                        fontFamily: 'inherit',
                        fontSize: '1.25rem',
                        fontSmoothing: 'antialiased',
                        ':-webkit-autofill': {
                            color: '#000000',
                        },
                        '::placeholder': {
                            color: '#d5d5d5',
                        },
                    },
                    invalid: {
                        iconColor: '#EF4444',
                        color: '#EF4444',
                    },
                },
            });
            cardElement.value.mount(container);
        }
    };

    const createToken = async (options = {}) => {
        if (!stripe.value || !cardElement.value) {
            toast.error('Stripe or card element is not initialized.');
            return 'error';
        }

        const response: any = await stripe.value.createToken(cardElement.value, options);

        if (response.error) {
            toast.error(response.error.message);
            return 'error';
        }

        /**
         * response.token:
         * {
         *   "id": "tok_1QT35KQZkxrJjOUDS8NT2PwI",
         *   "object": "token",
         *   "card": {
         *     "id": "card_1QT35KQZkxrJjOUD7ZhSyQaR",
         *     "object": "card",
         *     "address_city": null,
         *     "address_country": null,
         *     "address_line1": null,
         *     "address_line1_check": null,
         *     "address_line2": null,
         *     "address_state": null,
         *     "address_zip": "12322",
         *     "address_zip_check": "unchecked",
         *     "brand": "Visa",
         *     "country": "US",
         *     "cvc_check": "unchecked",
         *     "dynamic_last4": null,
         *     "exp_month": 10,
         *     "exp_year": 2033,
         *     "funding": "credit",
         *     "last4": "4242",
         *     "name": null,
         *     "networks": {
         *       "preferred": null
         *     },
         *     "tokenization_method": null,
         *     "wallet": null
         *   },
         *   "client_ip": "188.163.95.207",
         *   "created": 1733497490,
         *   "livemode": false,
         *   "type": "card",
         *   "used": false
         * }
         */

        return {
            token: response.token.id,
            card: response.token.card.id,
            brand: response.token.card.brand,
            last4: response.token.card.last4,
            address_zip: response.token.card.address_zip,
            exp_month: response.token.card.exp_month,
            exp_year: response.token.card.exp_year,
        };
    };

    return {
        stripe: stripe.value,
        cardElement: cardElement.value,
        initialStripe,
        initialElement,
        createToken
    };
});
